<template>
    <el-dialog title="资源新增" :visible.sync="visible" width="70%" v-if="visible" @close="handleClose">
        <el-row class="m_b2">
            <el-col :span="12">
                <el-input size="small" v-model="srhStr" placeholder="请输入搜索关键词" clearable class="m_r1" style="width: 60%" @keyup.enter.native="refreshList()"></el-input>
                <el-button type="primary" @click="refreshList(1)" size="small" icon="el-icon-search">查询</el-button>
                <el-button @click="reset()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-col>
            <el-col :span="12" class="text_right">
                <el-button type="primary" size="small" @click="batchAdd()" icon="el-icon-plus">批量新增</el-button>
            </el-col>
        </el-row>
        <div class="m_b1 tabBox">
            <el-button :type="item.id == tabIndex ? 'primary': ''"
                       size="small" round v-for="(item, index) in tabList"
                       :key="index" @click="tabChange(item)">{{item.name}}</el-button>
        </div>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                max-height="340"
                @selection-change="selectionChangeHandle"
                class="table" ref="multipleTable" row-key='id'>
            <el-table-column type="selection" :reserve-selection='true' fixed/>
            <el-table-column prop="exhibitionId" label="图片" width="110">
                <template slot-scope="scope">
                    <el-image fit="contain" :src="scope.row.thumb_path" class="itemImg" style="width: 100px; height: 100px;">
                        <el-image slot="placeholder" fit="cover" class="itemImg"
                                  :src="require('@/assets/img/thumbnail.png')"></el-image>
                        <el-image slot="error" fit="cover" class="itemImg"
                                  :src="require('@/assets/img/thumbnail.png')"></el-image>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="collection_num" sortable label="藏品编号"></el-table-column>
            <el-table-column show-overflow-tooltip prop="collection_name" label="藏品名称"></el-table-column>
            <el-table-column show-overflow-tooltip prop="resource_name" label="资源名称"></el-table-column>
            <el-table-column
                    prop="type_name"
                    label="类别" sortable show-overflow-tooltip>
            </el-table-column>
            <!--<el-table-column
                    prop="resource_keys"
                    label="标签" sortable show-overflow-tooltip>
            </el-table-column>-->
            <el-table-column
                    prop="resource_keys"
                    label="标签" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div v-if="scope.row.resource_keys!=null&& scope.row.resource_keys.length > 0">
                            <span style="margin-right: 5px"
                                  v-for="(item,index) in scope.row.resource_keys">{{item}};</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    width="150"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="add(scope.row)">新增</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageNo"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!--新增藏品-->
        <EditCollection ref="editCollection" @refreshDataList="refreshList"></EditCollection>
    </el-dialog>
</template>

<script>
    import EditCollection from './editCollection' // 编辑藏品

    export default {
        components: {EditCollection},

        data() {
          return {
              visible: false,
              srhStr: '',
              tabList: [
                  {
                      id: null,
                      name: '全部',
                  },
                  // {
                  //     id: '10',
                  //     name: '藏品资源',
                  // },
                  // {
                  //     id: '11',
                  //     name: '非藏品图片',
                  // },
                  {
                      id: '0',
                      name: '三维资源',
                  },
                  {
                      id: '1',
                      name: '图片资源',
                  },
                  {
                      id: '2',
                      name: '音频资源',
                  },
                  {
                      id: '3',
                      name: '视频资源',
                  },
                  {
                      id: '4',
                      name: '出版物资源',
                  },
                  {
                      id: '5',
                      name: '文献资源',
                  },
                  {
                      id: '6',
                      name: '文件资源',
                  },
                  {
                      id: '7',
                      name: '其他',
                  },
              ],
              tabIndex: null,
              dataList: [],
              dataListSelections: [],
              pageNo: 1,
              pageSize: 10,
              total: 0,
              loading: false
          }
        },
        methods: {
            init () {
                this.dataListSelections = []
                this.visible = true
                this.$nextTick(() => {
                    this.srhStr = ''
                    this.tabIndex = null
                    this.pageNo = 1
                    this.refreshList()
                })
            },
            // 查询
            refreshList(type) {
                if(type == 1) {
                    this.pageNo = 1
                }
                this.loading = true
                this.$axios(this.api.rcPage.adminQueryPage2, {
                    current: this.pageNo,
                    size: this.pageSize,
                    nameOrKeyName: this.srhStr,
                    resourceType: this.tabIndex
                }, "post").then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        this.total = parseInt(res.data.total)
                        this.loading = false
                    }
                })
            },
            // 批量新增
            batchAdd() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let list = []
                this.dataListSelections.map(row => {
                    list.push({
                        columnId: row.resource_type,
                        foreignKeyId: row.id,
                        foreignType: row.resource_type == null ? row.div_type : row.resource_type,
                        isShow: 0,
                        sort: 1,
                    })
                })
                this.$axios(this.api.rcPage.homeDetailsBatchSave, list, "post").then(data => {
                    if (data && data.status) {
                        this.$message.success("新增成功")
                        this.visible = false
                        this.$emit('refreshDataList')
                    }else{
                        this.$message.error(data.msg)
                    }
                })
            },
            // 新增
            add(row) {
                this.$refs.editCollection.init('add', row)
            },
            // 点击tab
            tabChange(item) {
                this.tabIndex = item.id
                this.refreshList(1)
            },
            reset() {
                this.$refs.multipleTable.clearSelection()
                this.srhStr = ''
                this.refreshList(1)
            },
            // 关闭
            handleClose() {
                this.$emit('refreshDataList')
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
        },
    }
</script>

<style scoped>
    .tabBox >>> .el-button+.el-button {
        margin-bottom: 10px;
    }
</style>
