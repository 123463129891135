<template>
    <el-dialog
            :title='"首页资源"+title'
            :close-on-click-modal="false"
            append-to-body
            :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" v-loading="loading" ref="inputForm"
                 @keyup.enter.native="doSubmit()"
                 label-width="150px" @submit.native.prevent>
            <el-form-item label="藏品名称：" prop="collection_name">
                <el-input v-model.trim="inputForm.collection_name" disabled style="width: 60%"></el-input>
            </el-form-item>
            <el-form-item label="资源名称：" prop="resource_name">
                <el-input v-model.trim="inputForm.resource_name" disabled style="width: 60%"></el-input>
            </el-form-item>
            <el-form-item label="栏目：" prop="columnId">
                <el-select v-model="inputForm.columnId" disabled placeholder="请选择" style="width: 60%">
                    <el-option
                            v-for="item in columnOpt"
                            :key="item.id"
                            :label="item.columnName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="排序号：" prop="sort">
                <el-input-number :step="1" :min="1" :max="127" v-model="inputForm.sort"
                                 placeholder="排序号"></el-input-number>
            </el-form-item>
            <el-form-item label="是否显示：" prop="isShow">
                <el-switch v-model="inputForm.isShow" :active-value="0" :inactive-value="1"></el-switch>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                title: '新增',
                visible: false,
                loading: false,
                inputForm: {
                    foreignKeyId: "",
                    collection_name: "",
                    resource_name: "",
                    sort: 1,
                    columnId: null,
                    isShow: 0,
                    foreignType: null,
                    id: null,
                },
                columnOpt: [],
                dataRule: {
                    id: [
                        {required: true, message: '栏目不能为空', trigger: 'blur'}
                    ],
                    sort: [
                        {required: true, message: '排序号不能为空', trigger: 'blur'}
                    ]
                },
            }
        },
        methods: {
            init(method, row) {
                this.visible = true
                this.inputForm.foreignKeyId = ""
                this.title = "新增"
                this.$nextTick(() => {
                    this.getColumn(method, row)
                    this.$refs['inputForm'].resetFields()
                    this.inputForm.collection_name = row.collection_name
                    this.inputForm.resource_name = row.resource_name
                    this.inputForm.foreignKeyId = row.id
                    this.inputForm.foreignType = row.resource_type == null ? row.div_type : row.resource_type
                    //this.inputForm = this.recover(this.inputForm, row)
                    if (method === 'edit') { // 修改
                        this.inputForm.columnId = row.colume_id
                        this.inputForm.sort = row.sort
                        this.inputForm.isShow = row.is_show
                        this.inputForm.foreignKeyId = row.foreign_key_id
                        this.inputForm.id = row.id
                        this.title = "修改"
                    }
                })
            },
            getColumn(method, row) {
                this.$axios(this.api.rcPage.homeColumn, {
                    'size': 20,
                    'current': 0,
                }, "get").then((res) => {
                    if (res.status) {
                        this.columnOpt = res.data.records
                        if (method != 'edit') {
                            let type = row.div_type == null ? row.resource_type : row.div_type
                            res.data.records.forEach(item => {
                                if (item.resourceType == type) {
                                    this.inputForm.columnId = item.id
                                }
                            })
                        }
                    }
                })
            },
            // 表单提交
            doSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.visible = true
                        if (this.title == "新增") {
                            this.$axios(this.api.rcPage.homeDetailsSave, this.inputForm, "post").then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message.success("操作成功")
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                }else{
                                    this.$message.error(data.msg)
                                }
                            })
                        } else {
                            this.$axios(this.api.rcPage.updateHomeDetails, this.inputForm, "post").then((res) => {
                                if (res.status) {
                                    this.$message.success(res.data)
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>
